import React, { useState } from 'react';
import { Container } from "react-bootstrap";
import Section from 'components/common/Section';
import Image from 'components/common/utils/Image';
import { Link } from "gatsby";
import { pathologyUrl } from "utils/pathologyUrlHelper";
import './styles.sass';
import Button from "react-bootstrap/Button";
import { Charles } from 'types/index';
import { AirtableImages } from 'components/common/utils/AirtableImage';
import { pathologiesMotherSlug } from 'templates/Pathologies/utils/slugs';

const THRESHOLD = 25
type CategoryPathologiesProps = {
  categoryRecord: Charles.CategoryRecord
}
const PathologiesGroup = ({ categoryRecord }: CategoryPathologiesProps) => {
  const [showAll, setShowAll] = useState(false)
  const pathologies: Charles.PathologyRecord[] = categoryRecord.data.pathologies
  const shouldTruncate = !showAll && pathologies.length >= THRESHOLD
  const shownPathologies = shouldTruncate ? pathologies.slice(0, 15) : pathologies
  return (
    <>
      <div className="pathologies-items">
        {shownPathologies.map((pathologyRecord: Charles.PathologyRecord) => (
          <Link className="pathologies-items-link" to={pathologyUrl(categoryRecord.data.slug, pathologyRecord.data.slug)} >
            {pathologyRecord.data.breadcrumb_label}
          </Link>
        ))}
      </div>
      {shouldTruncate && (
        <div className="pathologies-show-more">
          <Button
            variant=" "
            className="cta-show-more"
            onClick={() => setShowAll(true)}
          >
            Voir plus de pathologies
          </Button>
        </div>
        )
      }
    </>
  )
}

type Props = {
  categoriesRecords: Charles.CategoryRecord[]
  pathologies_title: string
  pathologies_subtitle: string
}

const CategoriesList = ({ categoriesRecords, pathologies_title, pathologies_subtitle }: Props) => (
  <Section>
    <Container>
      <div className="pathologies-list-component">
        <h2>{pathologies_title}</h2>
        <p className="pathologies-list-description mb-5">{pathologies_subtitle}</p>
        <div className="pathologies-list-wrapper">
          {categoriesRecords.map((categoryRecord) => (
            <div key={categoryRecord.id} className="pathologies-group">
              <div className="pathologies-title">
                <AirtableImages
                  images={categoryRecord.data.color_icon}
                  className="specialty-icon"
                />
                <Link to={pathologiesMotherSlug(categoryRecord.data.slug)}>
                  <h3 className="title">{categoryRecord.data.presentation}</h3>
                </Link>
              </div>
              <PathologiesGroup categoryRecord={categoryRecord} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  </Section>
);

export default CategoriesList;
